var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-workout--page"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.$attrs.id)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedData.title)+" ")]):_c('span',[_vm._v(" Create Recipe Details ")])]},proxy:true},{key:"top-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.isDeletingRecipe,"text":"","dark":""},on:{"click":_vm.deleteRecipe}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.icons.delete)+" ")]),_vm._v(" Delete Food Recipe ")],1)]},proxy:true},{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: 'form.recipe.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Recipe Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 2,"to":{
          name: 'form.recipe.ingredients',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","arrow-left":"","replace":""}},[_vm._v(" Ingredients ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 3,"to":{
          name: 'form.recipe.flavour-boosters',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","arrow-left":"","replace":""}},[_vm._v(" Flavour Boosters ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 4,"to":{
          name: 'form.recipe.directions',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","arrow-left":"","replace":""}},[_vm._v(" Directions ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 5,"to":{
          name: 'form.recipe.preview',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","replace":""}},[_vm._v(" Preview ")])]},proxy:true}])}),_c('div',{staticClass:"px-lg-12 px-md-6 px-5 mt-0",staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }