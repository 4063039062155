<template>
  <div class="bbr-workout--page">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedData.title }}
        </span>
        <span v-else> Create Recipe Details </span>
      </template>

      <template v-slot:top-actions>
        <v-btn
          color="error"
          @click="deleteRecipe"
          :loading="isDeletingRecipe"
          text
          dark
        >
          <v-icon class="mr-3"> {{ icons.delete }} </v-icon>
          Delete Food Recipe
        </v-btn>
      </template>

      <template v-slot:actions>
        <arrow-button
          :done="stage > 1"
          :to="{
            name: 'form.recipe.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Recipe Details
        </arrow-button>

        <arrow-button
          :done="stage > 2"
          :to="{
            name: 'form.recipe.ingredients',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          arrow-left
          replace
        >
          Ingredients
        </arrow-button>

        <arrow-button
          :done="stage > 3"
          :to="{
            name: 'form.recipe.flavour-boosters',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          arrow-left
          replace
        >
          Flavour Boosters
        </arrow-button>

        <arrow-button
          :done="stage > 4"
          :to="{
            name: 'form.recipe.directions',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          arrow-left
          replace
        >
          Directions
        </arrow-button>

        <arrow-button
          :done="stage > 5"
          :to="{
            name: 'form.recipe.preview',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          replace
        >
          Preview
        </arrow-button>
      </template>
    </main-app-bar>

    <div class="px-lg-12 px-md-6 px-5 mt-0" style="position: relative">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'RecipeDetailPages',

  components: {
    ConfirmDialog,
    MainAppBar,
    ArrowButton,
  },

  data() {
    return {
      isSaved: false,
      isDeletingRecipe: false,
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.foods.filter,
      selectedData: (state) => state.foods.selectedData,
    }),

    next() {
      return this.$attrs.id || false
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },
  },

  async created() {
    let content = this.$attrs.id

    if (content) {
      await this.getFoodCategory({ search: null })
      await this.fetchData(content)
    }
  },

  methods: {
    ...mapActions({
      getSingleData: 'foods/getSingleData',
      getFoodCategory: 'foodCategory/getDataList',
      deleteFoodRecipe: 'recipe/deleteRecipe',
    }),

    ...mapMutations({
      setFilter: 'foods/setFilter',
      clearList: 'foods/clearList',
      removeRecipeOnList: 'recipe/removeRecipeOnList',
    }),

    async fetchData(id) {
      await this.getSingleData(id)
    },

    async deleteRecipe() {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Food Recipe',
        'Are you sure you want to remove this food recipe?'
      )

      if (confirm) {
        this.isDeletingRecipe = true

        let food = this.selectedData

        await this.deleteFoodRecipe(food.id)
        await this.removeRecipeOnList(food.id)

        setTimeout(() => {
          this.$router.push({
            name: food.completed_at ? 'active.recipe' : 'draft.recipe',
          })
        }, 800)
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearList()

    next()
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}
</style>
